.st0{
    fill:#F7F7F7;
    stroke:#9E9E9E;
    stroke-miterlimit:10;
    font-family: 'Poppins', sans-serif;
    transition: 300ms;
}
.st0:hover{
    stroke-miterlimit:10;
    font-family: 'Poppins', sans-serif;
    stroke: #ff7208;
    stroke-width: 4;
    stroke-linecap: round
}
.st1{
    fill:none;
    stroke:#9E9E9E;
    stroke-miterlimit:10;
}

.st2{
    fill:#000000;
    position: relative;
}


.st2:hover{
    stroke: #ff7208;

}
.st2:hover:has(.st0){
    fill:#000000;
}

.st2{
    cursor: pointer;;
}
.st3{
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.st4{
    font-size:12px;
    cursor: pointer;
}
.st5{
    font-size:9.242px;
    cursor: pointer;
}
.st6{
    cursor: pointer;
    fill:#000000;
}
.containMap{
    position: relative;
}
