@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}
.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }
  .content-logo{
    background-color: #ff7208;
    display: flex;
    justify-content: center;
  }
  .logo {
    background-image: url('../public/img/logo-gobernacion.jpeg');
    background-repeat: no-repeat, repeat;
    background-position: center; 
    background-size: cover; 
    height: 64px;
    width: 190px;
  }
  .btn-detalle{
    background-color: #17a2b8 !important;
    color: white !important;
    border-color: #17a2b8 !important;
}
.btn-excel{
  border: 1px solid green !important;
  color: green !important;
}

.btn-excel{
  border: 1px solid green !important;
  color: 
  green !important;
}
.btn-remove{
  background-color: #ff7208 !important;
  color: white !important;
  border-color: #ff7208 !important;
}

.btn-bloqued{
  background-color: red !important;
  color: white !important;
  border-color: red !important;
}
.btn-active{
  background-color: green !important;
  color: white !important;
  border-color: green !important;
}
  .site-layout .site-layout-background {
    background: #fff;
  }
  header{
    background-color: #ff7208 !important;
    color: #fff !important;
    font-weight: bold;
  }

.btn-primary{
  background-color: #ff7208;
  color: #fff;
  border-color: #FF9649;
}
.btn-primary:hover{
  background-color: #FF9649;
  color: #fff;
  border-color: #FF9649;
}
.btn-primary:focus{
  background-color: #FF9649;
  color: #fff;
  border-color: #FF9649;
}
Header{
  display: flex;
  justify-content: space-between;
  padding-right: 30px !important;
}
.logo-principal{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.content-regresar{
  display: flex;
  justify-content: center;
}
.btn-back{
  border: 1px solid #ff7208;
  color: #ff7208;
}
.btn-recuperar{
  border: 1px solid #17a2b8 !important;
  color: #ffff !important;
  background-color: #17a2b8 !important;
}
.btn-back:hover{
  border: 1px solid #ff7208;
  background-color: #FF9649;
  color: #fff;
}
.alert-hogar{
  font-size: 10px;
  cursor: pointer;
  color: #FF9649;
  display: flex;
  justify-content: space-between;
  /* text-decoration: underline; */
}
.alert-hogar strong{
  color: #17a2b8;
}
.ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
  padding: 7px;

}
.ol-list{
  margin-left: -40px;
}
td{
  width: auto !important;
}
.content-recupera{
  background: radial-gradient(circle at bottom, #ff7208 , #ff9e95);
}
  .div-recuperar form{
     width: 100%;
    }
  .title-header{
    background-color: #ff7208  !important;
    width: 100%;
    text-align: center;
  }
  .title-header h2{
    color: #fff ;
  }
@media only screen and (max-width: 720px) {
  .logo-principal img{
    width: 100%;
   }

   .content-logo .logo{
    background-image: url('../public/img/logo-gobernacion.jpeg');
    background-repeat: no-repeat, repeat;
    background-position: center; 
    background-size: cover; 
    width: 200px;
   }
   .content-logo{
    height: 64px;
    display: flex;
   }
}
@media (min-width: 576px) and (max-width: 991.98px) and (orientation:portrait) {
  .logo-principal img{
   width: 100%;
  }
  .content-logo .logo{
    background-image: #ff7208;
   }
  
}

@media only screen and (min-width: 300px) and (max-width: 639px) {
  .ant-drawer-right>.ant-drawer-content-wrapper{
    width: 100% !important;
  }
}

.form-create label{
  font-weight: 600;
    color: #000;
}
.noEditForm .ant-checkbox+span{
  color: #626262 !important;
  font-weight: 600;
  font-size: 15px;
}
.noEditForm  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after{
  border-color: #ff7208;
}
.noEditForm .ant-radio-disabled+span{
  color: #626262 !important;
  font-weight: 600;
  font-size: 15px;
}
.noEditForm .ant-radio-disabled .ant-radio-inner:after{
  background-color: #ff7208 !important;
}
.noEditForm .ant-input[disabled]{
  color: #000 !important;
  font-weight: 600;
  font-size: 15px;
}
.noEditForm .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  color: #000 !important;
  font-weight: 600;
  font-size: 15px;
}
.noEditForm .ant-radio-group{
  display: flex;
  flex-direction: column;
}

.formNormal .ant-radio-group{
  display: flex;
  flex-direction: column;
}

.noEditForm .ant-checkbox-group{
  display: flex;
  flex-direction: column;
}

.formNormal .ant-checkbox-group{
  display: flex;
  flex-direction: column;
}

.formNormal .ant-form-item-label label{
  font-weight: 600;
  font-size: 15px;
}

.noEditForm .ant-form-item-label label{
  font-weight: 600;
  font-size: 15px;
}
.resultPoint{
  background-color: #ff7208 !important;
  color: #fff !important;
}