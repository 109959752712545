.cardsContenedor{
    text-align: center;
}
.cardsContenido{
    padding: 20px 0px 20px 20px;
    background-color: #ff7208 !important ;
}
.ant-statistic-title{
    color: white;
}
.contenedor{
    display: flex;
    justify-content: center;
}
@media(max-width:992px){
    .cardsContenedor{
        padding-bottom: 15px;
    }
}