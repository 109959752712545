.content-login{
    height:100vh;
    padding-left:10px;
    padding-right:10px;
    display: flex;
    justify-content: center;
    align-items: center ;
    align-content: center;

}
.content-login form{
    width: 400px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(255,150,73,0.18);
    -moz-box-shadow: 0px 0px 9px 0px rgba(255,150,73,0.18);
    box-shadow: 0px 0px 9px 0px rgba(255,150,73,0.18);
    border-radius: 5px;
}
.content-login form img{
    margin:auto;
    margin-bottom: 20px;
}
.content-login form button{
    background-color: #ff7208;
    color: #fff;
    width: 100%;
    border-color: #FF9649;
}
.content-login form button:hover{
    background-color: #ff7208;
    color: #fff;
    border-color: #FF9649;
}
